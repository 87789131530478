import axios from 'axios'
import config from './config.js'
import qs from 'qs'

const URL = config.url
// console.log(URL)
const service = axios.create({
  baseURL: URL, // 请求api地址
  timeout: 30000, // 超时时间 毫秒
  withCredentials: false, // 表示跨域请求时是否需要使用凭证
})

export default {
  /*
    @func
    @desc axios get 请求
    @param {string} a 接口地址
    @param {object} b = {} 请求参数
  */
  get (url, params = {}) {
    qs.stringify(params);
    return new Promise((resolve, reject) => {
      service({
        method: 'get',
        url,
        params,
      }).then(({ data }) => {
        resolve(data)

      })
    })
  },
  post (url, data = {}) {
    qs.stringify(data);
    return new Promise((resolve, reject) => {
      service({
        method: 'post',
        url,
        data,
      }).then(({ data }) => {
        resolve(data)
      })
    })
  },
}
